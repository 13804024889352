
import { defineComponent, ref, onMounted } from 'vue';
import AppContent from '@/bases/AppContent.vue';
import ProgressStep from '@/bases/ProgressStep.vue';
import Button from '@/bases/Button.vue';
import '@/assets/main.css';
import '@/assets/stepProgress.css';
import { useStore } from 'vuex';
import router from '@/router';

export default defineComponent({
  components: { AppContent, Button, ProgressStep },
  setup() {
    const store = useStore();
    onMounted(() => {
      if (
        store.state.token.token === null ||
        store.state.untill === false ||
        Date.now() > store.state.untll
      ) {
        router.push('/login');
      }
      // IDが格納されてるとき
      if (store.state?.parameter?.ids) {
        router.push('/execution');
      }
    });
    const isValid = ref(true);

    const setParam = async () => {
      const para = document.getElementById('driveURL') as HTMLInputElement;
      const reg = new RegExp('https://docs.google.com/presentation/d/.*');
      if (!reg.test(para.value)) {
        isValid.value = false;
        return;
      }
      const id = para.value.split('/')[para.value.split('/').length - 2];
      store.commit('setParameter', JSON.stringify({ action: 'open', ids: [id] }));
      //router.push('/selectType');
      router.push('/execution');
    };
    return {
      setParam,
      isValid,
    };
  },
});
