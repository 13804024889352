
import { defineComponent, reactive, ref } from 'vue';
import AppContent from '../bases/AppContent.vue';
//import Button from '../bases/Button.vue';
import ModalMsg from '../bases/ModalMsg.vue';
import '../assets/main.css';
import { useStore } from 'vuex';
import router from '@/router';
import Tab from '../components/Tab.vue';
import { ElAvatar } from 'element-plus';

declare var gapi: any;

export default defineComponent({
  components: { AppContent, Tab, ElAvatar },
  setup() {
    const store = useStore();
    const userEmail = store.state.userDomain;
    const userPicture = store.state.userPicture;
    console.log(userEmail);
    const toInputUrl = () => {
      router.push('/inputUrl');
    };
    return {
      toInputUrl,
      userEmail,
      userPicture,
    };
  },
  data() {
    return {
      totalingScreenNames: '',
    };
  },
  mounted() {
    const store = useStore();
    this.totalingScreenNames = confSlides.stickyNote.totalingScreenNames['slides']; //eslint-disable-line
  },
});
