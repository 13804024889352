import { createStore, Store } from 'vuex';
import { InjectionKey } from 'vue';
import createPersistedState from 'vuex-persistedstate';

interface State {
  parameter: UrlParameter | null;
  userDomain: string | null;
  userPicture: string | null;
  token: {
    token: string | null;
    untill: number | false;
  };
  notesData: any | null;
}
export const key: InjectionKey<Store<State>> = Symbol();

export default createStore<State>({
  plugins: [
    createPersistedState({
      key: 'store',
      paths: ['token', 'parameter', 'notesData', 'userDomain', 'userPicture'],
      storage: window.localStorage, // sessionStorage
    }),
  ],
  state: {
    parameter: null,
    userDomain: null,
    userPicture: null,
    token: {
      token: localStorage.getItem('store')
        ? JSON.parse(localStorage.getItem('store') as string).token.token
        : null,
      untill: localStorage.getItem('store')
        ? JSON.parse(localStorage.getItem('store') as string).token.untill
        : false,
    },
    notesData: null,
  },
  mutations: {
    initialize(state: State) {
      state.token = {
        token: null,
        untill: false,
      };
      state.parameter = null;
      state.userDomain = null;
      state.userPicture = null;
      state.notesData = null;
    },
    setToken(
      state: State,
      token: {
        token: string;
        untill: number;
      }
    ) {
      state.token = token;
    },
    setUserDomain(state: State, domain: string | null) {
      state.userDomain = domain;
    },
    setUserPicture(state: State, picture: string | null) {
      state.userPicture = picture;
    },
    setParameter(state: State, param: string | null) {
      if (param !== null) {
        try {
          state.parameter = JSON.parse(param) as UrlParameter;
        } catch {
          state.parameter = null;
        }
      } else {
        state.parameter = param;
      }
    },
    setNotesData(state: State, notes: any | null) {
      state.notesData = notes;
    },
  },
  getters: {
    getToken(state) {
      return state.token;
    },
    getUserDomain(state) {
      return state.userDomain;
    },
    getUserPicture(state) {
      return state.userPicture;
    },
    getParameter(state) {
      return state.parameter;
    },
    getNotesData(state) {
      return state.notesData;
    },
  },
});
