//
// kuromoji集計
// kuromojiのtokenの集計を行う
// 品詞と個数を返す
export function kuromojiAggExe(tokens, partTypes) {
  // reduceによる集計
  const totalling = tokens.reduce((acc, key) => {
    // 名詞で数の場合は特殊処理
    if (key.pos === '名詞' && key.pos_detail_1 === '数' && !partTypes.includes('数')) {
      return acc;
    } else if (!partTypes.includes(key.pos)) {
      // partTypesに指定されていない品詞は除外する
      return acc;
    }
    // 名詞+サ変接続+undefinedは複合記号になるっぽいので無視する
    if (key.pos === '名詞' && key.pos_detail_1 === 'サ変接続' && key.reading === undefined)
      return acc;

    // acc内に存在する場合は+1する
    if (key.surface_form in acc) {
      acc[key.surface_form]++;
    } else {
      // 初回
      acc[key.surface_form] = 1;
    }
    return acc;
  }, {});
  return totalling;
}

//
// スペシャルキーワードマージ
//   品詞分解しないで集計したいキーワードを指定。
export function specialKeywordMerge(tokens, specialKeyword) {
  //特別キーワードを結合する
  const retTokens = [];
  let skipCnt = 0;

  for (let cnt = 0; cnt < tokens.length; ++cnt) {
    if (skipCnt !== 0) {
      // 特殊キーワードとして使ったワードをスキップする
      cnt = skipCnt;
      skipCnt = 0;
      continue;
    }
    if (specialKeyword.indexOf(tokens[cnt].surface_form) === 0) {
      skipCnt = 0;
      // 前方一致したら確認する
      let keyword1chk = tokens[cnt].surface_form;
      for (let cnt2 = cnt + 1; cnt2 < tokens.length; ++cnt2) {
        keyword1chk += tokens[cnt2].surface_form;
        if (specialKeyword.length < keyword1chk.length) {
          // 文字列がOverしたのでこれは違う
          break;
        }
        if (specialKeyword.indexOf(keyword1chk) === 0) {
          if (specialKeyword.length === keyword1chk.length) {
            // キーワードが一致した
            retTokens.push({
              pos: '特殊キーワード',
              surface_form: specialKeyword,
            });
            skipCnt = cnt2;
            break;
          }
        }
      }
    } else {
      retTokens.push(tokens[cnt]);
    }
  }
  return retTokens;
}
