import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
} from 'vue-router';
import PageHome from '../pages/PageHome.vue';
import PageLogin from '../pages/PageLogin.vue';
import PageInputUrl from '../pages/PageInputUrl.vue';
import PageExecution from '../pages/PageExecution.vue';
//import PageResult from '../pages/_PageResult.vue';
import PageResult from '../pages/PageResult.vue';
import PageSelectType from '../pages/PageSelectType.vue';
import store from '../store';
const routes: Array<any> = [
  {
    path: '/',
    name: 'home',
    component: PageHome,
  },
  {
    path: '/login',
    name: 'login',
    component: PageLogin,
  },
  {
    path: '/execution',
    name: 'execution',
    component: PageExecution,
    meta: { requiresAuth: true },
  },
  {
    path: '/inputUrl',
    name: 'inputUrl',
    component: PageInputUrl,
    meta: { requiresAuth: true },
  },
  {
    path: '/selectType',
    name: 'selectType',
    component: PageSelectType,
    meta: { requiresAuth: true },
  },
  {
    path: '/result',
    name: 'result',
    component: PageResult,
    meta: { requiresAuth: true },
  },
  {
    //不明なページは全てホームにリダイレクトさせる
    path: '/:catchAll(.*)',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
//ナビゲーションガード
router.beforeEach(
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    if (to.query.state) {
      store.commit('setParameter', to.query.state);
    }
    const state = store.state;
    const token = state.token;
    const dt = Date.now();
    const isEnabledToken = () => {
      return token.token !== null && token.untill !== false && dt < token.untill;
    };
    if (to.matched.some((record) => record.meta.requiresAuth) && !isEnabledToken()) {
      next('/login');
    } else {
      next();
    }
  }
);
export default router;
