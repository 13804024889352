import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f5f55338"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, {
      gutter: 10,
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          xs: 24,
          sm: 24,
          md: 24,
          lg: 24,
          xl: 18
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ]))
}