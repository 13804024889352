
import { defineComponent, onMounted } from 'vue';
import AppContent from '../bases/AppContent.vue';
import ProgressStep from '../bases/ProgressStep.vue';
import '../assets/main.css';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
declare var google: any;
declare var gapi: any;
declare var window: any;

export default defineComponent({
  components: { AppContent, ProgressStep },
  setup() {
    const router = useRouter();
    const store = useStore();

    onMounted(() => {
      let url = new URL(window.location.href);
      let params = url.searchParams;
      let id = params.get('id');
      if (id) {
        store.commit('setParameter', JSON.stringify({ action: 'open', ids: [id] }));
      }
      if (store.state.token.token !== null && Date.now() < store.state.token?.untill) {
        router.push('/inputUrl');
      }
    });

    const CLIENT_ID = '219916649275-7bk6lr50b03rld97ek85du6afhlco6v3.apps.googleusercontent.com'; //'513774583744-852phovi1k22e5ccl5mmjqoj3hns3u1r.apps.googleusercontent.com';
    const API_KEY = 'AIzaSyBKdHoFnvjr8OGJniF9UQblKO1hDV0aQJs'; //'AIzaSyAXpncCFwR2Vm9l9vFgnEDBwpYabTV_u-E';
    const DISCOVERY_DOC = 'https://slides.googleapis.com/$discovery/rest?version=v1';
    const SCOPES =
      'https://www.googleapis.com/auth/presentations \
      https://www.googleapis.com/auth/userinfo.email';
    let tokenClient: any;
    let gapiInited = false;
    let gisInited = false;

    window.onGapiLoaded = gapiLoaded();
    gisLoaded();
    function gapiLoaded() {
      gapi.load('client', initializeGapiClient);
    }

    async function initializeGapiClient() {
      await gapi.client.init({ apiKey: API_KEY, discoveryDocs: [DISCOVERY_DOC] }).then(() => {
        gapi.client.setToken({ access_token: store.state.token.token });
      });
      gapiInited = true;
      EnableLoginButton();
    }
    function gisLoaded() {
      tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: CLIENT_ID,
        scope: SCOPES,
        callback: '', // defined later
      });
      gisInited = true;
      EnableLoginButton();
    }
    function EnableLoginButton() {
      let btn = document.getElementById('loginBtn');
      if (btn && gisInited && gapiInited) {
        btn.style.display = 'inline';
      }
    }

    const login = () => {
      tokenClient.callback = async (response: any) => {
        if (response.error !== undefined) {
          throw response;
        }
        const dt = Date.now();
        store.commit('setToken', {
          token: response.access_token,
          untill: dt + response.expires_in * 1000,
        });
        gapi.client.load('oauth2', 'v2', function () {
          gapi.client.oauth2.userinfo.get().execute(function (resp: any) {
            // Shows user email
            console.log(resp);
            store.commit('setUserDomain', resp.email);
            store.commit('setUserPicture', resp.picture);
          });
        });
        const param = store.getters.getParameter;
        if (!param || param.action === 'create') {
          router.push('/inputUrl');
        } else if (param.action === 'open') {
          router.push('/inputUrl');
        } else {
          router.push('execution');
        }
      };

      if (gapi.client.getToken() === null) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        tokenClient.requestAccessToken({ prompt: 'consent' });
        console.log('prompt');
      } else {
        // Skip display of account chooser and consent dialog for an existing session.
        //tokenClient.requestAccessToken({ prompt: '' });
        console.log('none');
        tokenClient.requestAccessToken({ prompt: '' });
      }
    };
    return {
      login,
    };
  },
});
