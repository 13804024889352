
import { defineComponent, reactive, ref, onMounted } from 'vue';
import AppContent from '../bases/AppContent.vue';
import ProgressStep from '@/bases/ProgressStep.vue';
import Button from '../bases/Button.vue';
import ModalMsg from '../bases/ModalMsg.vue';
import { GoogleAppsScript } from '../apis/googleScriptRun';
import '../assets/main.css';
import { useStore } from 'vuex';
import { MinLogger, LOG_LEVEL } from 'min-logger';
import router from '@/router';
declare var gapi: any;
declare var Chart: any;
declare var window: any;

export default defineComponent({
  components: { AppContent, Button, ModalMsg, ProgressStep },
  setup() {
    const store = useStore();

    const minLogger = new MinLogger({
      endpointUrl: 'https://logging-3k3hdubxja-an.a.run.app', //ポストするエンドポイントのURLを指定します。規定値は空
      logKey: 'log', // StorageAPIに格納する際のkeyを指定します。規定値は'log'
      outputLocalStorageLevel: LOG_LEVEL.WARN, // localStorageに格納するログレベルを指定します。LOG_LEVEL.DEBUG ~ LOG_LEVEL.ERRORが指定可能規定値はLOG_LEVEL.WARN
      outputEndpointLevel: LOG_LEVEL.INFO, // エンドポイントに出力するログレベルを指定します。LOG_LEVEL.DEBUG ~ LOG_LEVEL.ERRORが指定可能規定値はLOG_LEVEL.ERROR
      maxLogLocalStorage: 300, // localStorageに格納する最大数を指定します。整数値を指定可能規定値は300
    });

    onMounted(() => {
      if (
        store.state.token.token === null ||
        store.state.untill === false ||
        Date.now() > store.state.untll
      ) {
        router.push('/login');
      }

      // IDが格納されていないとき
      if (!store.state?.parameter?.ids) {
        alert(`システムエラーが発生しました。`);
        router.push('/inputUrl');
      }
    });
    console.log('ふせんconfig');
    console.log(confSlides.stickyNote); //eslint-disable-line

    //const client = store.state.client;
    //client.requestAccessToken({ prompt: 'consent' });

    const status = ref<'nothing' | 'running' | 'success' | 'failure'>('nothing');
    const progressState = reactive({
      running: false,
      msg: '',
      max: 0,
      now: 0,
    });
    let resultURL = '';
    const target = store.state.parameter.ids[0];
    const targetURL = 'https://docs.google.com/presentation/d/' + target;
    const templateType = store.state.parameter.templateType;

    //const CLIENT_ID = '513774583744-852phovi1k22e5ccl5mmjqoj3hns3u1r.apps.googleusercontent.com';
    const API_KEY = 'AIzaSyBKdHoFnvjr8OGJniF9UQblKO1hDV0aQJs'; //'AIzaSyAXpncCFwR2Vm9l9vFgnEDBwpYabTV_u-E';
    const DISCOVERY_DOC = 'https://slides.googleapis.com/$discovery/rest?version=v1';

    let gapiInited = false;
    window.onGapiLoaded = initializeGapiClient();

    async function initializeGapiClient() {
      gapi.load('client:auth2', () => {
        gapi.client
          .init({
            apiKey: API_KEY,
            discoveryDocs: [DISCOVERY_DOC],
          })
          .then(
            () => {
              gapi.client.setToken({ access_token: store.state.token.token });
              gapiInited = true;
              console.log('setToken');
            },
            (error: any) => {
              alert(error['details']);
            }
          );
      });
    }

    function CheckGapiInited() {
      if (gapiInited === false) {
        window.setTimeout(CheckGapiInited, 100);
      } else {
        aggregate();
      }
    }
    CheckGapiInited();

    function runAsyncFunctionWithTimeout(asyncFunction: any, delay: number, ...args: any[]) {
      setTimeout(() => {
        asyncFunction(...args).catch((error: any) => {
          console.error(error);
        });
      }, delay);
    }

    async function aggregate(retry = 0) {
      progressState.running = true;
      status.value = 'running';
      const params = store.state.parameter;
      const retryLimit = 5;

      // IDが格納されていないとき
      if (!store.state?.parameter?.ids) {
        alert(`システムエラーが発生しました。`);
        router.push('/inputUrl');
      }

      let response;
      try {
        response = await gapi.client.slides.presentations.get({
          presentationId: params.ids[0],
        });
      } catch (err: any) {
        console.log(err.message);
        console.log(err.status);
        minLogger.log('Suite集計', store.state.userDomain, err);
        if (err.status === 400) {
          // リクエストでクライアント エラーが発生したため、リクエストを処理できません。
          alert(`${err.status}:システムエラーが発生しました。`);
          minLogger.log(
            'Suite集計',
            store.state.userDomain,
            `${err.status}:システムエラーが発生しました。`
          );
        } else if (err.status === 401) {
          // リクエストに無効な認証情報が含まれている。ログイン画面へ戻す。
          alert(`${err.status}:指定したファイルにアクセスできません。`);
          minLogger.log(
            'Suite集計',
            store.state.userDomain,
            `${err.status}:指定したファイルにアクセスできません。`
          );
        } else if (err.status === 403) {
          // Forbidden リクエストは受信され理解されましたが、ユーザーにはリクエストを実行する権限がありません。
          alert(`${err.status}:指定したファイルにアクセスできません。`);
          minLogger.log(
            'Suite集計',
            store.state.userDomain,
            `${err.status}:指定したファイルにアクセスできません。`
          );
        } else if (err.status === 404) {
          // リクエストされたページが見つからない。リトライ処理
          minLogger.log(
            'Suite集計',
            store.state.userDomain,
            `${err.status}:指定したファイルにアクセスできません。`
          );
          if (retry < retryLimit) {
            retry++;
            runAsyncFunctionWithTimeout(aggregate, 1000, retry);
            return;
          } else {
            minLogger.log(
              'Suite集計',
              store.state.userDomain,
              `${err.status}:リトライの上限回数を超えました。指定したファイルにアクセスできません。`
            );
            alert(
              `${err.status}:リトライの上限を超えました。指定したファイルにアクセスできません。`
            );
            store.commit('setParameter', null);
          }
        } else if (err.status === 429) {
          // API へのリクエスト数が多すぎます。
          minLogger.log(
            'Suite集計',
            store.state.userDomain,
            `${err.status}:システムエラーが発生しました。`
          );
          alert(`${err.status}:システムエラーが発生しました。`);
        } else if (
          err.status === 500 ||
          err.status === 502 ||
          err.status === 503 ||
          err.status === 504
        ) {
          // リクエストの処理中に予期しないエラーが発生しました。リトライ処理
          minLogger.log(
            'Suite集計',
            store.state.userDomain,
            `${err.status}:システムエラーが発生しました。`
          );
          if (retry < retryLimit) {
            retry++;
            runAsyncFunctionWithTimeout(aggregate(retry), 1000);
            return;
          } else {
            minLogger.log(
              'Suite集計',
              store.state.userDomain,
              `${err.status}:リトライの上限回数を超えました。時間をおいて、再度試してください。`
            );
            alert(`${err.status}:リトライの上限を超えました。時間をおいて、再度試してください。`);
            store.commit('setParameter', null);
          }
        } else {
          // その他のエラー処理
        }
        // idが存在してたらidの引継ぎ
        if (store.state?.parameter?.ids) {
          let id = store.state.parameter.ids[0];
          store.commit('initialize');
          store.commit('setParameter', JSON.stringify({ action: 'open', ids: [id] }));
        } else {
          // idなければ初期化だけ
          store.commit('initialize');
        }

        router.push('/login');
        return;
      }
      // ここまでエラー処理

      const presentation = response.result;
      if (!presentation || !presentation.slides || presentation.slides.length == 0) {
        console.log('No slides found.');
        minLogger.log(
          'Suite集計',
          store.state.userDomain,
          `スライドに集計対象のデータが存在しません。`
        );
        return;
      }

      console.log(presentation);
      let stickyNotes: any = [];
      const output = presentation.slides.reduce((str: any, slide: any, index: any) => {
        const numElements = slide.pageElements?.length;
        slide.pageElements?.reduce((str: any, element: any, index: any) => {
          if (element.title == 'ZETTA_STICKYNOTE') {
            //console.log('descripton:' + element.description);
            const description = JSON.parse(element.description);
            description.textParagrahs = [];
            const text = element.shape?.text?.textElements.reduce((str: any, tElem: any) => {
              var content = tElem.textRun?.content?.replace('\n', '') ?? '';
              if (content != '') {
                description.textParagrahs.push(content);
              }
              return str + content;
            }, '');
            description.image = element.image?.contentUrl;
            description.text = text?.replace(/\s+/g, '');
            if (description.image) {
              description.shapeType = 'IMAGE';
            }
            stickyNotes.push(description);
            //console.log(description);
            //console.log(text.replace(/\s+/g, '')); //スペースを削除したtext);
          }
        }, 'test');
        return `${str}- Slide #${index} contains ${numElements} elements\n`;
      }, 'Slides:\n');

      console.log(output);
      console.log(stickyNotes);
      //writeChart(stickyNotes);
      params.title = presentation.title;
      params.ids = null;
      store.commit('setParameter', JSON.stringify(params));
      store.commit('setNotesData', stickyNotes);
      status.value = 'success';
      progressState.running = false;
      openResult();
    }

    const toSelectType = () => {
      const params = store.state.parameter;
      params.ids = null;
      store.commit('setParameter', JSON.stringify(params));
      router.push('/inputUrl');
    };
    const openResult = () => {
      router.push('/result');
      //window.open(resultURL, '_blank');
    };

    return {
      status,
      aggregate,
      progressState,
      openResult,
      toSelectType,
      target,
      templateType,
      targetURL,
    };
  },
});
