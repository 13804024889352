
import { defineComponent } from 'vue';
import PageHeader from './components/PageHeader.vue';
import PageFooter from './components/PageFooter.vue';
import './assets/main.css';
export default defineComponent({
  name: 'App',
  components: {
    PageHeader,
    PageFooter,
  },
});
