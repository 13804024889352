
import { defineComponent, ref } from 'vue';
import '../assets/stepProgress.css';
export default defineComponent({
  props: {
    max: {
      default: '3',
      type: String,
    },
    value: {
      default: '0',
      type: String,
    },
  },
  setup(props) {
    const maxStep = Number(props.max);
    const nowStep = ref(props.value);
    return {
      maxStep,
      nowStep,
    };
  },
});
