export function wordCloudGraphDraw(
  tokensAggs,
  graphDiv,
  fontSizeMin,
  fontSizeMax,
  fontSizeMode,
  graphWidth,
  graphHeight,
  cutNum
) {
  let drawWordLength = 0;

  // 出現数最大取得
  const tokenNumMax = Math.max(...Object.values(tokensAggs));
  //console.log(`最大出現数${tokenNumMax}`);
  //const tokenNumMin = Math.min(...Object.values(tokensAggs));
  //console.log(`最小出現数${tokenNumMax}`);
  const tokenNumMin = fontSizeMode === 'MODE1' ? 1 : cutNum;

  while (graphDiv.lastChild) {
    graphDiv.removeChild(graphDiv.lastChild);
  }
  const myWords = [];
  let counter = 0;

  // フォント係数を最大出現数から算出
  const fontSizeRatio =
    tokenNumMax - tokenNumMin === 0 ? 1 : (fontSizeMax - fontSizeMin) / (tokenNumMax - tokenNumMin);

  //グラフ用配列
  for (const key in tokensAggs) {
    // 出現個数チェック 未満は飛ばす。
    if (cutNum > tokensAggs[key]) continue;
    // フォントサイズ
    const size = (tokensAggs[key] - tokenNumMin) * fontSizeRatio + fontSizeMin;
    console.log(`fontSize:${size} 個数${tokensAggs[key]}`);
    const myWord = {
      word: key,
      size: size,
      color: d3.schemeCategory10[counter % 10],
    };
    myWords.push(myWord);
    counter++;
  }
  // グラフの表示設定
  const margin = { top: 10, right: 10, bottom: 10, left: 10 },
    width = graphWidth - margin.left - margin.right,
    height = graphHeight - margin.top - margin.bottom;

  // svgオブジェクトの追加
  const svg = d3
    .select('#wordcloud')
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

  // インスタンスの作成
  const layout = d3.layout
    .cloud()
    .size([width, height])
    .words(
      myWords.map(function (d) {
        return { text: d.word, size: d.size, color: d.color };
      })
    )
    .padding(5) //単語の距離
    .rotate(function () {
      // return ~~(Math.random() * 2) * 90;
      return 0;
    })
    .fontSize(function (d) {
      return d.size;
    }) // フォントサイズ
    .on('end', draw);
  // layout.startでワードのレイアウトが行われる。
  layout.start();
  if (drawWordLength < myWords.length) {
    return false;
  } else {
    return true;
  }
  // layoutの出力を受け取り単語を描画
  // layout.startの中で呼び出される。
  // layout.startで描写ができないワードはこの時点でなくなっている。
  // パラメタのwordsの中からはじかれている。
  function draw(words) {
    drawWordLength = words.length;
    svg
      .append('g')
      .attr('transform', 'translate(' + layout.size()[0] / 2 + ',' + layout.size()[1] / 2 + ')')
      .selectAll('text')
      .data(words)
      .enter()
      .append('text')
      .style('font-size', function (d) {
        return d.size;
      })
      .attr('fill', function (d) {
        return d.color;
      })
      .attr('text-anchor', 'middle')
      .attr('font-weight', 'bold')
      .style('font-family', 'BIZ UDPGothic')
      .attr('transform', function (d) {
        return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')';
      })
      .text(function (d) {
        return d.text;
      });
  }
}
