
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    height: {
      default: '300px',
      type: String,
    },
    width: {
      default: '450px',
      type: String,
    },
  },
  setup(props) {
    const styles = computed(() => {
      return {
        '--props-height': props.height,
        '--props-width': props.width,
      };
    });
    const imgsrc = computed(() => {
      return `../DrSimpler/DrSimpler${Math.floor(Math.random() * 11) + 1}.png`;
    });

    return {
      styles,
      imgsrc,
    };
  },
});
