
import { defineComponent, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import imgCopy from '../assets/clipboard-copy.svg';
import {
  ElButton,
  ElLink,
  ElTableColumn,
  ElTabPane,
  ElRow,
  ElTable,
  ElTabs,
  ElForm,
  ElFormItem,
  ElSelect,
  ElCol,
  ElOption,
  ElInput,
  ElCheckboxButton,
  ElRadioButton,
  ElRadioGroup,
  ElCheckboxGroup,
  ElDialog,
} from 'element-plus';
import 'element-plus/dist/index.css';
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';
import Lightgallery from 'lightgallery/vue';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import { lightGalleryCoreSettings } from 'lightgallery/lg-settings';
import { AnyColumn } from 'element-plus/es/components/table-v2/src/common';
import html2canvas from 'html2canvas';

import * as cloudword from '@/js/cloudword';
import * as graph from '@/js/graph';
declare var confSlides: any;
declare var Chart: any;
declare var ChartDataLabels: any;
declare var pattern: any;
let lightGallery: any = null;
let lightGallery2: any = null;

export default defineComponent({
  components: {
    ElButton,
    ElLink,
    ElTable,
    ElTableColumn,
    ElRow,
    ElTabPane,
    ElTabs,
    Splitpanes,
    Pane,
    Lightgallery,
    ElForm,
    ElFormItem,
    ElCol,
    ElSelect,
    ElOption,
    ElInput,
    ElCheckboxButton,
    ElCheckboxGroup,
    ElRadioButton,
    ElRadioGroup,
    ElDialog,
  },
  data() {
    return {
      tableData: [{ type: '', text: '' }],
      isVisibleBarTable: false,
      isVisibleBarImages: false,
      plugins: [lgThumbnail, lgZoom],
      licenseKey: '99A2D965-4B4A-4352-A0CA-8E762EAD9882',
      selectedBarText: '',
      wordform: reactive({
        shukei: '1',
        colors: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
        class: ['1'],
        minCount: '1',
        minFontSize: '9',
        maxFontSize: '64',
        imgHeight: '500',
        imgWidth: '500',
        keyword1: '',
        keyword2: '',
        keyword3: '',
        keyword4: '',
        keyword5: '',
      }),
      dataURL: '',
      dialogVisible: false,
    };
  },
  methods: {
    onlgInit: (detail: any) => {
      lightGallery = detail.instance;
    },
    onlgInit2: (detail: any) => {
      lightGallery2 = detail.instance;
    },
    copyHtml(html: any, plainText: any) {
      const blobHtml = new Blob([html], { type: 'text/html' });
      const blobPlain = new Blob([plainText], { type: 'text/plain' });
      // eslint-disable-next-line no-undef
      const data = [new ClipboardItem({ 'text/html': blobHtml, 'text/plain': blobPlain })];

      navigator.clipboard.write(data).then(
        () => {
          console.log('success');
          alert('クリップボードにコピーしました');
        },
        (msg) => {
          console.log(`fail: ${msg}`);
          alert('クリップボードにコピーできませんでした');
        }
      );
    },
    copyBarTable() {
      // <table> 要素と <tbody> 要素を作成
      var tbl = document.createElement('table');
      var tblBody = document.createElement('tbody');

      // テーブルヘッダの作成
      var rHedder = document.createElement('tr');
      var th = document.createElement('th');
      var th2 = document.createElement('th');
      th.textContent = 'ふせんの形';
      th2.textContent = '内容';
      rHedder.appendChild(th);
      rHedder.appendChild(th2);
      tbl.appendChild(rHedder);

      for (var i = 0; i < this.tableData.length; i++) {
        var row = document.createElement('tr');
        for (var j = 0; j < 2; j++) {
          row.insertCell();
        }
        row.style.height = '20px';
        tblBody.appendChild(row);
      }
      this.tableData.reduce(
        (pRow: any, nRow: any, index: any) => {
          tblBody.rows[index].cells[0].innerHTML = nRow.type;
          tblBody.rows[index].cells[1].innerHTML = nRow.text;
          tblBody.rows[index].cells[0].style.color = '#000000';
          tblBody.rows[index].cells[1].style.color = '#000000';
          return { type: '', text: '' };
        },
        { type: '', text: '' }
      );

      // <tbody> を <table> の中に追加
      tbl.appendChild(tblBody);
      tbl.setAttribute('border', '1');
      console.log(tbl.outerHTML);
      this.copyHtml(tbl?.outerHTML, '');
    },
    //-----------------------------------------------------
    //
    // ワードクラウド作成
    //
    //-----------------------------------------------------
    async kuromojiParse() {
      //document.getElementById('divResultText')!.style.display = 'none';
      /*const rubyImage = document.getElementById('rubyImage')!;
      while (rubyImage.firstChild) {
        rubyImage.removeChild(rubyImage.firstChild);
      }*/
      let notesData = this.store.state.notesData;
      let parseText = '';
      notesData?.reduce((str: any, note: any, index: any) => {
        if (this.wordform.shukei == '1') {
          // 品詞分解
          if (this.wordform.colors.indexOf(String(note.noteTypesSeq)) != -1) {
            parseText += note.text ? note.text + '　' : '';
          }
        } else {
          // ふせん毎
          if (this.wordform.colors.indexOf(String(note.noteTypesSeq)) != -1) {
            let text = note.textParagrahs ? note.textParagrahs[0] : null;
            parseText += text ? text + '\n' : '';
          }
        }
      }, '');

      console.log(this.wordform);
      // 処理する文
      // スペシャルキーワード
      const specialKeywords = [];
      const keyword1 = this.wordform.keyword1;
      if (keyword1.length > 0) specialKeywords.push(keyword1);
      const keyword2 = this.wordform.keyword2;
      if (keyword2.length > 0) specialKeywords.push(keyword2);
      const keyword3 = this.wordform.keyword3;
      if (keyword3.length > 0) specialKeywords.push(keyword3);
      const keyword4 = this.wordform.keyword4;
      if (keyword4.length > 0) specialKeywords.push(keyword4);
      const keyword5 = this.wordform.keyword5;
      if (keyword5.length > 0) specialKeywords.push(keyword5);
      // 処理対象品詞
      const partTypes = ['特殊キーワード'];
      if (this.wordform.class.indexOf('1') != -1) partTypes.push('名詞');
      if (this.wordform.class.indexOf('2') != -1) partTypes.push('動詞');
      if (this.wordform.class.indexOf('3') != -1) partTypes.push('副詞');
      if (this.wordform.class.indexOf('4') != -1) partTypes.push('形容詞');
      if (this.wordform.class.indexOf('5') != -1) partTypes.push('連体詞');
      if (this.wordform.class.indexOf('6') != -1) partTypes.push('感動詞');
      if (this.wordform.class.indexOf('7') != -1) partTypes.push('数');
      // 処理対象個数
      const cutNum = Number(this.wordform.minCount);
      // 最大フォントサイズ
      const fontSizeMax = Number(this.wordform.maxFontSize);
      // 最小フォントサイズ
      const fontSizeMin = Number(this.wordform.minFontSize);
      // 描写幅、高さ
      const graphWidth = Number(this.wordform.imgWidth);
      const graphHeight = Number(this.wordform.imgHeight);

      //kuromoji処理
      let kuromojiTokens;
      let data = { text: parseText };
      if (this.wordform.shukei == '1') {
        // 品詞分解
        await fetch('https://asia-northeast1-suite-webapi.cloudfunctions.net/kuromoji', {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          body: parseText, // 本体のデータ型は "Content-Type" ヘッダーと一致させる必要があります
        })
          .then((response) => response.json())
          .then((data) => (kuromojiTokens = data))
          .catch((error) => console.error(error));
      } else {
        // ふせん毎
        kuromojiTokens = [];
        //1行を単語と見なす処理
        partTypes.push('名詞');
        const notes = parseText.split('\n');
        notes.forEach((text) => {
          //ダミーでkuromojiTokensを作成する
          kuromojiTokens.push({
            //トリムで空白除去。冒頭からスペース半角までの文字列。20文字まで
            surface_form: text.replace('　', ' ').trim().split(' ')[0].substr(0, 20),
            basic_form: '',
            conjugated_form: '',
            pos: '名詞',
            pos_detail_1: '名詞',
            pos_detail_2: '一般',
            pos_detail_3: '',
            pronunciation: '一般',
            reading: '',
            word_type: '',
          });
        });
      }

      console.log(kuromojiTokens);
      //スペシャルキーワード ここでは２つ処理
      for (let specialKeyword of specialKeywords) {
        kuromojiTokens = cloudword.specialKeywordMerge(kuromojiTokens, specialKeyword);
      }

      // 集計処理 品詞の除外処理もここで行う。
      const tokensAggs = cloudword.kuromojiAggExe(kuromojiTokens, partTypes);
      const graphDiv = document.getElementById('wordcloud');

      // グラフ描写
      const resultGraph = graph.wordCloudGraphDraw(
        tokensAggs,
        graphDiv,
        fontSizeMin,
        fontSizeMax,
        'MODE1',
        graphWidth,
        graphHeight,
        cutNum
      );
      document.getElementById('wordcloud')!.style.display = 'block';
      document.getElementById('wordcloud')!.style.width = graphWidth + 'px';

      await this.capture();
      document.getElementById('wordcloud')!.style.display = 'none';

      if (resultGraph === false) {
        /*alert(
          'すべてのワードが表示されません。描写サイズを上げるか最大フォントサイズを下げてください。'
        );*/
        this.dialogVisible = true;
      }
    },
    selectAllColor() {
      this.wordform.colors = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
    },
    selectAllClass() {
      this.wordform.class = ['1', '2', '3', '4', '5', '6', '7'];
    },
    async capture() {
      const el = this.$refs.capture as HTMLElement;
      const params: Parameters<typeof html2canvas> = [
        el,
        {
          // オプションを指定、一旦飛ばします
          width: Number(this.wordform.imgWidth),
          height: Number(this.wordform.imgHeight),
        },
      ];
      const canvasElement = await html2canvas(...params).catch((e) => {
        console.error(e);
        return;
      });
      if (!canvasElement) {
        return;
      }
      this.dataURL = canvasElement.toDataURL();
      this.$nextTick(() => {
        lightGallery2?.refresh();
      });
    },
  },
  setup() {
    const store = useStore();
    const notesData = store.state.notesData;
    const activeName = ref('third');
    // イベントの追加
    // イベント関数はreturnで返す必要がある。
    const alertDisplay = () => {
      window.alert('表示');
    };
    const pasteDataCreate = () => {
      const tsvData = transTableData.map((row: any) => Object.values(row).join('\t')).join('\n');
      navigator.clipboard.writeText(tsvData).then(
        () => alert('クリップボードにコピーしました'),
        () => alert('クリップボードにコピーできませんでした')
      );
    };

    const copyPieChartGraph = () => {
      var canvas = document.getElementById('pieChart') as HTMLCanvasElement;
      pasteGraphCreate(canvas);
    };
    const copyBarChartGraph = () => {
      var canvas = document.getElementById('barChart') as HTMLCanvasElement;
      pasteGraphCreate(canvas);
    };
    const copyBarChart2Graph = () => {
      var canvas = document.getElementById('barChart2') as HTMLCanvasElement;
      pasteGraphCreate(canvas);
    };

    const pasteGraphCreate = (canvas: HTMLCanvasElement) => {
      if (!canvas || !canvas.getContext) {
        return false;
      }
      //. Canvas の内容を PNG 画像として取得
      var png = canvas.toDataURL('image/png');
      png = png.replace(/^.*,/, '');

      //. バイナリ変換
      var bin = atob(png);
      var buffer = new Uint8Array(bin.length);
      for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
      }
      var blob = new Blob([buffer], { type: 'image/png' }); //. イメージバッファから Blob を生成

      try {
        navigator.clipboard.write([
          // eslint-disable-next-line no-undef
          new ClipboardItem({
            'image/png': blob,
          }),
        ]);
      } catch (err) {
        console.log(err);
        alert('クリップボードにコピーできませんでした');
      }
      alert('クリップボードにコピーしました');
    };

    const handleClick = (tab: any, event: any) => {
      console.log(tab, event);
    };

    // 受け取った配列を回転するワンライナーコード
    const transpose = (a: any) => a[0].map((_: any, c: any) => a.map((r: any) => r[c]));

    //色順にまとめる
    const group = notesData.reduce((rv: any, x: any) => {
      var data = Object();
      if (x.text) {
        data.type = 'TEXT';
        data.text = x.text;
      } else {
        data.type = 'IMAGE';
        data.text = x.image;
        console.log(data);
      }
      (rv[x['noteTypesSeq']] = rv[x['noteTypesSeq']] || []).push(data);
      return rv;
    }, {});

    // 二次元配列化
    // 二次元配列の最大値は後で求める事も出来るが同じループ内で済ませた方が速度上有利
    const tableData: any[] = [];
    let maxLength = 0;
    for (let colorCnt = 0; colorCnt < 9; ++colorCnt) {
      const key = String(colorCnt + 1);
      if (key in group) {
        tableData.push(group[String(colorCnt + 1)]);
        const arryLength = group[String(colorCnt + 1)].length;
        maxLength = maxLength < arryLength ? arryLength : maxLength;
      } else {
        const arry: any = [];
        tableData.push(arry);
      }
    }
    //配列を合わせる
    tableData.forEach((e: any, index: any) => {
      while (e.length < maxLength) {
        e.push('');
      }
    });

    // 縦横変換
    // 列が色、行がテキスト
    console.log(transpose(tableData));
    const transTableData = transpose(tableData);

    const copyHtml = (html: any, plainText: any) => {
      const blobHtml = new Blob([html], { type: 'text/html' });
      const blobPlain = new Blob([plainText], { type: 'text/plain' });
      // eslint-disable-next-line no-undef
      const data = [new ClipboardItem({ 'text/html': blobHtml, 'text/plain': blobPlain })];

      navigator.clipboard.write(data).then(
        () => {
          console.log('success');
          alert('クリップボードにコピーしました');
        },
        (msg) => {
          console.log(`fail: ${msg}`);
          alert('クリップボードにコピーできませんでした');
        }
      );
    };

    // テーブルコピー用
    function copyTable() {
      let noteTypes = confSlides.stickyNote2.noteTypes.slice();
      noteTypes.sort(function (first: any, second: any) {
        if (first.dispSeq > second.dispSeq) {
          return 1;
        } else if (first.dispSeq < second.dispSeq) {
          return -1;
        } else {
          return 0;
        }
      });
      console.log(noteTypes);
      // <table> 要素と <tbody> 要素を作成
      var tbl = document.createElement('table');
      var tblBody = document.createElement('tbody');

      // テーブルヘッダの作成
      var rHedder = document.createElement('tr');
      var tableData: any = [];
      noteTypes.reduce((str: any, item: any, index: any) => {
        var colorData: any = [];
        tableData.push(colorData);
      }, '');
      notesData?.reduce((str: any, note: any, index: any) => {
        tableData[confSlides.stickyNote2.noteTypes[note.noteTypesSeq - 1].dispSeq - 1].push(
          note.text ? note.text : note.image
        );
      }, '');

      noteTypes.reduce((str: any, item: any, index: any) => {
        var th = document.createElement('th');
        th.textContent = item.colorName;
        // th.style.backgroundColor = item.bgColor;
        th.style.color = item.borderColor;
        rHedder.appendChild(th);
      }, '');
      tbl.appendChild(rHedder);

      for (var i = 0; i < maxLength; i++) {
        var row = document.createElement('tr');
        for (var j = 0; j < 9; j++) {
          row.insertCell();
        }
        row.style.height = '20px';
        tblBody.appendChild(row);
      }
      tableData.reduce((str: any, column: any, index: any) => {
        column.reduce((str: any, item: any, index2: any) => {
          if (item?.indexOf('https:') === 0) {
            tblBody.rows[index2].cells[index].innerHTML = '画像'; //'<a href="' + item + '">画像</a>';
            //'<img src="' + item + '" style="width: 90px; height: 90px;"/>';
          } else {
            tblBody.rows[index2].cells[index].innerHTML = item;
            tblBody.rows[index2].cells[index].style.color = '#000000';
          }
        }, '');
      }, '');
      // <tbody> を <table> の中に追加
      tbl.appendChild(tblBody);
      tbl.setAttribute('border', '1');
      console.log(tbl.outerHTML);
      copyHtml(tbl?.outerHTML, '');
    }

    //transpose(tableData).forEach((e: any, index: any) => {});
    return {
      transTableData,
      alertDisplay,
      pasteDataCreate,
      activeName,
      handleClick,
      copyPieChartGraph,
      copyBarChartGraph,
      copyBarChart2Graph,
      copyTable,
      store,
    };
  },
  mounted() {
    const store = useStore();
    const notesData = store.state.notesData;

    let noteTypes = confSlides.stickyNote2.noteTypes.slice();
    noteTypes.sort(function (first: any, second: any) {
      if (first.dispSeq > second.dispSeq) {
        return 1;
      } else if (first.dispSeq < second.dispSeq) {
        return -1;
      } else {
        return 0;
      }
    });

    const changeBarTableData = (type: number, dispSeq: number) => {
      this.tableData.splice(0);
      switch (type) {
        case 0:
          type = 1;
          break;
        case 1:
          type = 2;
          break;
        case 2:
          type = 0;
          break;
        default:
          break;
      }
      let color = noteTypes[dispSeq].seq - 1;
      console.log(noteTypes);
      console.log(confSlides.stickyNote2.noteTypes);
      console.log(confSlides.stickyNote2.stekyTypes[type]);
      console.log(confSlides.stickyNote2.noteTypes[color].colorName);
      const stekyType = confSlides.stickyNote2.stekyTypes[type];
      this.selectedBarText =
        confSlides.stickyNote2.noteTypes[color].colorName +
        ':' +
        confSlides.stickyNote2.stekyTypesName[stekyType];
      console.log(this.selectedBarText);
      notesData?.reduce((str: any, note: any, index: any) => {
        if (
          note.noteTypesSeq == color + 1 &&
          note.shapeType == confSlides.stickyNote2.stekyTypes[type]
        ) {
          this.tableData.push({
            type: confSlides.stickyNote2.stekyTypesName[note.shapeType],
            text: note.text ? note.text : note.image,
          });
        }
      }, '');
      if (confSlides.stickyNote2.stekyTypes[type] == 'IMAGE') {
        this.isVisibleBarImages = true;
        this.isVisibleBarTable = false;
      } else {
        this.isVisibleBarImages = false;
        this.isVisibleBarTable = true;
      }
      this.$nextTick(() => {
        lightGallery?.refresh();
      });
    };

    writePieChart(notesData);
    writeBarChart(notesData);
    writeBar2Chart(notesData);

    type DataSet = {
      data: number[];
      backgroundColor: string[];
      label: string;
      borderColor?: string[];
      borderWidth?: number;
    };

    function writePieChart(data: any) {
      var content = document.getElementById('tab1_canvas');
      document.getElementById('barChart')?.remove();
      let labels: string[] = [];
      let dataSets: DataSet[] = [
        {
          data: [],
          backgroundColor: [],
          label: '',
        },
      ];
      noteTypes.reduce((str: any, item: any, index: any) => {
        labels.push(item.colorName);
        dataSets[0].data.push(0);
        dataSets[0].backgroundColor.push(item.bgColor);
      }, '');
      data?.reduce((str: any, note: any, index: any) => {
        dataSets[0].data[confSlides.stickyNote2.noteTypes[note.noteTypesSeq - 1].dispSeq - 1] += 1;
      }, '');
      console.log(dataSets);
      let pieCtx = document.createElement('canvas');
      pieCtx.height = innerHeight * 0.6;
      pieCtx.id = 'pieChart';
      // 円グラフの設定
      let pieConfig = {
        type: 'doughnut',
        data: {
          labels: labels,
          datasets: dataSets,
        },
        plugins: [ChartDataLabels],
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              enabled: false,
            },
            // グラフ内に値も表示
            datalabels: {
              align: 'center',
              anchor: 'center',
              font: {
                size: 12,
                weight: 200,
              },
              color: '#FFFFFF',
              textStrokeColor: '#434343',
              textStrokeWidth: 4,
              formatter: function (value: any, context: any) {
                let sum = 0;
                let dataArr = context.chart.data.datasets[0].data;
                dataArr.map((data: any) => {
                  sum += data;
                });
                let percentage = ((value * 100) / sum).toFixed(2) + '%';
                if (Number(value) == 0) {
                  return '';
                } else {
                  return value.toString() + ' (' + percentage + ')';
                }
              },
            },
          },
        },
      };
      let pieChart = new Chart(pieCtx, pieConfig);
      content?.appendChild(pieCtx);
    }

    function writeBarChart(data: any) {
      var content = document.getElementById('tab2_2_content');
      document.getElementById('barChart')?.remove();
      let labels: string[] = [];
      let dataSets: DataSet[] = [
        {
          label: 'メモ',
          data: [],
          backgroundColor: [],
          borderColor: ['#FFFFFF'],
          borderWidth: 0,
        },
        {
          label: 'ふきだし',
          data: [],
          backgroundColor: [],
          borderColor: ['#FFFFFF'],
          borderWidth: 1,
        },
        {
          label: 'しかく',
          data: [],
          backgroundColor: [],
          borderColor: ['#FFFFFF'],
          borderWidth: 1,
        },
        {
          label: '画像',
          data: [],
          backgroundColor: [],
          borderColor: ['#FFFFFF'],
          borderWidth: 1,
        },
      ];
      noteTypes.reduce((str: any, item: any, index: any) => {
        labels.push(item.colorName);
        for (var i = 0; i < 4; i++) {
          dataSets[i].data.push(0);
        }
        // グラフの色初期化処理
        dataSets[0].backgroundColor?.push(item.bgColor);
        dataSets[1].backgroundColor?.push(pattern.draw('plus', item.bgColor));
        dataSets[2].backgroundColor?.push(pattern.draw('dot', item.bgColor));
        dataSets[3].backgroundColor?.push(pattern.draw('box', item.bgColor));
        console.log(dataSets);
      }, '');
      data?.reduce((str: any, note: any, index: any) => {
        let seq = confSlides.stickyNote2.noteTypes[note.noteTypesSeq - 1].dispSeq - 1;
        switch (note.shapeType) {
          case 'FOLDED_CORNER':
            dataSets[0].data[seq] += 1;
            break;
          case 'WEDGE_RECTANGLE_CALLOUT':
            dataSets[1].data[seq] += 1;
            break;
          case 'RECTANGLE':
            dataSets[2].data[seq] += 1;
            break;
          case 'IMAGE':
            dataSets[3].data[seq] += 1;
            break;
        }
      }, '');
      console.log(dataSets);
      let barCtx = document.createElement('canvas');
      barCtx.id = 'barChart';

      // 棒グラフの設定
      let barConfig = {
        type: 'bar',
        data: {
          labels: labels,
          datasets: dataSets,
        },
        plugins: [ChartDataLabels],
        options: {
          onClick: (e: any, activeEls: any) => {
            let datasetIndex = activeEls[0].datasetIndex;
            let dataIndex = activeEls[0].index;
            let datasetLabel = e.chart.data.datasets[datasetIndex].label;
            let value = e.chart.data.datasets[datasetIndex].data[dataIndex];
            let label = e.chart.data.labels[dataIndex];
            console.log('In click', datasetLabel, label, value, datasetIndex, dataIndex);
            changeBarTableData(datasetIndex, dataIndex);
          },
          plugins: {
            tooltip: {
              enabled: false,
            },
            // グラフ内に値も表示
            datalabels: {
              align: 'center',
              anchor: 'center',
              font: {
                size: 12,
                weight: 200,
              },
              color: '#FFFFFF',
              textStrokeColor: '#434343',
              textStrokeWidth: 4,
              formatter: function (value: any, context: any) {
                if (Number(value) == 0) {
                  return '';
                } else {
                  return value.toString();
                }
              },
            },
          },
          indexAxis: 'y',
          scales: {
            x: {
              stacked: true,
              ticks: {
                stepSize: 1,
              },
            },
            y: {
              stacked: true,
            },
          },
          responsive: true,
        },
      };
      let barChart = new Chart(barCtx, barConfig);
      content?.appendChild(barCtx);
    }

    function writeBar2Chart(data: any) {
      var content = document.getElementById('tab2_content');
      document.getElementById('barChart2')?.remove();
      let labels: string[] = [];
      let dataSets: DataSet[] = [
        {
          data: [],
          backgroundColor: [],
          label: 'ふせんの数',
        },
      ];
      noteTypes.reduce((str: any, item: any, index: any) => {
        labels.push(item.colorName);
        dataSets[0].data.push(0);
        dataSets[0].backgroundColor.push(item.bgColor);
      }, '');
      data?.reduce((str: any, note: any, index: any) => {
        dataSets[0].data[confSlides.stickyNote2.noteTypes[note.noteTypesSeq - 1].dispSeq - 1] += 1;
      }, '');
      console.log(dataSets);
      let barCtx = document.createElement('canvas');
      barCtx.id = 'barChart2';

      // 棒グラフの設定
      let barConfig = {
        type: 'bar',
        data: {
          labels: labels,
          datasets: dataSets,
        },
        plugins: [ChartDataLabels],
        options: {
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
            // グラフ内に値も表示
            datalabels: {
              align: 'center',
              anchor: 'center',
              font: {
                size: 12,
                weight: 200,
              },
              color: '#FFFFFF',
              textStrokeColor: '#434343',
              textStrokeWidth: 4,
              formatter: function (value: any, context: any) {
                if (Number(value) == 0) {
                  return '';
                } else {
                  return value.toString();
                }
              },
            },
          },
          indexAxis: 'y',
          scales: {
            x: {
              ticks: {
                stepSize: 1,
              },
            },
          },
          responsive: true,
        },
      };
      let barChart2 = new Chart(barCtx, barConfig);
      content?.appendChild(barCtx);
    }

    function CreateTableData(data: any) {
      var tableData: any = [];
      noteTypes.reduce((str: any, item: any, index: any) => {
        var colorData: any = [];
        tableData.push(colorData);
      }, '');
      data?.reduce((str: any, note: any, index: any) => {
        let seq = confSlides.stickyNote2.noteTypes[note.noteTypesSeq - 1].dispSeq - 1;
        tableData[seq].push(note.text);
      }, '');
      return tableData;
    }
  },
});
